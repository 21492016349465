var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "back",
        class: _vm.isborder === "true" ? "borderbox" : "",
        style: _vm.bgcol
      },
      [
        _c("div", { class: _vm.iswhite === "true" ? "whitefont" : "" }, [
          _vm.backIcon
            ? _c("i", {
                staticClass: "el-icon-arrow-left",
                class: _vm.iswhite === "true" ? "whitefont" : "",
                on: { click: _vm.backbtn }
              })
            : _vm._e(),
          _c("div", { staticClass: "centerbox" }, [_vm._v(_vm._s(_vm.title))])
        ]),
        _vm.isbaofei
          ? [
              _c(
                "div",
                { staticClass: "righbox", on: { click: _vm.rightbtnParams } },
                [_vm._v(_vm._s(_vm.righbox))]
              )
            ]
          : [
              _c(
                "div",
                { staticClass: "righbox", on: { click: _vm.rightbtn } },
                [_vm._v(_vm._s(_vm.righbox))]
              )
            ]
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }